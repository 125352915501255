* {
  outline: none;
}

img {
  color: transparent; //So we don't see alt text while the image loads
}

a {
  text-decoration: none;
  color: inherit;
}
