/* ==========================================================================
   # Divider
   ========================================================================== */

@mixin tl-divider {
  hr {
    border: 0;
    border-top: 0.1em solid $color-quarter-tone;
    margin: 3em 0;
  }
}
