@mixin all-caps() {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
}

%all-caps {
  @include all-caps();
}

@mixin all-caps-wide() {
  @include all-caps();
  letter-spacing: 0.3em;
}

%all-caps-wide {
  @include all-caps-wide();
}

@mixin all-caps-wide-bold() {
  letter-spacing: 0.19em;
  font-weight: 700;
}

%all-caps-wide-bold {
  @include all-caps-wide-bold();
}

/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
*/

@mixin mark-caps-responsive-sizing($font-size: 10px) {
  @extend %all-caps-wide;

  @each $size, $factor in $cap-grid-text-factors {
    @include media-breakpoint-only($size) {
      //@include rem(font-size, 0.6rem * $factor);
      font-size: $font-size * $factor;
    }
  }
}

%mark-caps-responsive-sizing {
  @include mark-caps-responsive-sizing();
}
