.media-container {
  overflow: hidden;

  video,
  img {
    margin: 0;
  }

  //Image fallback
  &.media-container-video-fallback {
    video {
      display: none;
    }

    &.use-video {
      video {
        display: block;
      }
      .media-image {
        display: none;
      }
    }
  }

  //Fitting classes

  &.media-container-fit-width {
    video,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &.media-container-cover {
    video,
    img {
      @include absolute-fill-parent;
      object-fit: cover;
    }
  }

  &.media-container-contain {
    video,
    img {
      @include absolute-fill-parent;
      object-fit: contain;
    }
  }
}
