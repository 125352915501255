.page-content {
  position: relative;
  z-index: 0;
}

@each $part in 'padding', 'margin' {
  .content-#{$part},
  .content-#{$part}-double,
  .content-#{$part}-h,
  .content-#{$part}-double-h {
    #{$part}-left: $cap-general-padding;
    #{$part}-right: $cap-general-padding;
  }

  @include media-breakpoint-up('md') {
    .content-#{$part}-double,
    .content-#{$part}-double-h {
      #{$part}-left: $cap-general-padding * 2;
      #{$part}-right: $cap-general-padding * 2;
    }
  }

  .content-#{$part}gin,
  .content-#{$part}-double,
  .content-#{$part}-v,
  .content-#{$part}-double-v {
    #{$part}-top: $cap-general-padding;
    #{$part}-bottom: $cap-general-padding;
  }

  @include media-breakpoint-up('md') {
    .content-#{$part}-double,
    .content-#{$part}-double-v {
      #{$part}-top: $cap-general-padding * 2;
      #{$part}-bottom: $cap-general-padding * 2;
    }
  }

  .content-#{$part}-quadruple-bottom {
    #{$part}-bottom: $cap-general-padding * 4;
  }
}

.page-section {
  margin-bottom: $cap-general-padding * 4;
}

@include media-breakpoint-up('sm') {
  .text-content {
    max-width: 505px;
  }
}

@include media-breakpoint-up('xxl') {
  .text-content {
    max-width: 640px;
    max-width: 640 * $xxl-sizing-factor;
  }
}
