.contact-list {
  line-height: 1.2;

  h3 {
    @include all-caps();
    margin: 1.2em 0;
  }

  > li {
    margin: 1.2em 0 2.4em 0;
  }

  a {
    text-decoration: inherit;
    font-weight: inherit;
  }
}
