.stores-countries {
  line-height: 1.2;
  list-style: none;
  padding: 0;

  > li {
    display: inline-block;
    width: 100%;
  }
  h3 {
    margin: 0;
    @include all-caps();
  }

  .store-list {
    list-style: none;
    padding: 0;
    margin: 1.2em 0 1.2em 0;
    > li {
      margin: 1.2em 0;
    }
  }

  .store-name {
    margin: 0;
  }

  .store-address {
    a {
      text-decoration: inherit;
    }
  }

  a.store-link {
    display: block;
    text-decoration: inherit;
  }
}

@include media-breakpoint-up('md') {
  .stores-countries {
    column-count: 2;
    max-width: 900px;
  }
}

@include media-breakpoint-up('xxl') {
  .stores-countries {
    max-width: 1150px;
    max-width: 1150 * $xxl-sizing-factor;
  }
}
