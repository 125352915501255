/* ==========================================================================
   # Typography
   ========================================================================== */

@mixin tl-typography {
  b,
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 1em;
    margin-bottom: 2.4em;
    margin-top: 2.4em;
    &:first-child {
      margin-top: 0;
    }
  }
}
