.no-js .lazyload {
  display: none;
}

.lazyautosizes,
.lazyload,
.lazyloading,
.lazyloaded {
  transition: opacity 600ms;
}

.nolazyload,
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
}
