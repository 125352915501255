/* ==========================================================================
   # Spacing
   ========================================================================== */

@mixin tl-spacing {
  .button,
  button,
  dd,
  dt,
  li {
    margin-bottom: 1em;
  }

  fieldset,
  input,
  select,
  textarea {
    margin-bottom: 1.5em;
  }

  blockquote,
  dl,
  figure,
  form,
  ol,
  p,
  pre,
  table,
  ul {
    margin-bottom: 1.2em;
  }
}
