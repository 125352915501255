/* ==========================================================================
   # Blockquote
   ========================================================================== */

@mixin tl-blockquote {
  blockquote {
    margin-left: 0;
    margin-right: 0;
    padding: 1em 1.5em;

    *:last-child {
      margin-bottom: 0;
    }
  }
}
