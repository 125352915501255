$edge-space-basis: 20px;

@mixin edge-item-side-spacing($property, $basis) {
  @each $size, $factor in $cap-grid-spacing-factors {
    @include media-breakpoint-only($size) {
      #{$property}: $basis * $factor;
    }
  }
}

%edge-item {
  position: absolute;
  white-space: nowrap;
  //padding: $edge-space-basis / 2;
  @extend %mark-caps-responsive-sizing;
}

%edge-item-top {
  @extend %edge-item;
  @include edge-item-side-spacing(top, $edge-space-basis);
  left: 50%;
  transform: translate(-50%, 0);
}

%edge-item-left {
  @extend %edge-item;
  @include edge-item-side-spacing(left, $edge-space-basis);
  top: 50%;
  transform: translate(-50%, 0) rotate(-90deg) translate(0, 50%);
}
%edge-item-right {
  @extend %edge-item;
  @include edge-item-side-spacing(right, $edge-space-basis);
  top: 50%;
  transform: translate(50%, 0) rotate(90deg) translate(0, 50%);
}
%edge-item-bottom {
  @extend %edge-item;
  @include edge-item-side-spacing(bottom, $edge-space-basis);
  left: 50%;
  transform: translate(-50%, 0);
}

.edge-text-items {
  position: absolute;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .edge-text-item-top {
    @extend %edge-item-top;
  }

  .edge-text-item-left {
    @extend %edge-item-left;
  }

  .edge-text-item-right {
    @extend %edge-item-right;
  }

  .edge-text-item-bottom {
    @extend %edge-item-bottom;
  }
}
