.collection-header {
  font-size: 12px;
  h2 {
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
    font-size: inherit;
  }
  nav {
    letter-spacing: 0.1em;
    li {
      display: inline-block;
      padding-right: 0.8em;
      &:last-child {
        padding-right: 0;
      }
      a {
        text-decoration: none;
      }
    }
    li:nth-last-child(n + 2),
    li:nth-last-child(n + 2) ~ li {
      &.active a {
        font-weight: bold;
      }
    }
  }
}

.collection-credits {
}

@include media-breakpoint-up("sm") {
  .collection-credits__item {
    display: inline;

    &::after {
      content: " — ";
    }
    &:last-child::after {
      content: none;
    }
  }
}

// .collection-credits {
//   font-size: 12px;
//   text-align: right;

//   margin: $cap-general-padding / 2 $cap-general-padding;

//   @include media-breakpoint-up('sm') {
//     margin: $cap-general-padding;
//   }
// }

#header {
  .collection-header {
    h2 {
      margin: 8px 0;
    }
  }
}

#header-dynamic-element {
  .collection-credits,
  .collection-header h2 {
    //Use ellipsis when text is too long
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }
}
