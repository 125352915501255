.splash {
  $splash-color: #fff;

  color: $splash-color;
  .page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    mix-blend-mode: difference;
  }

  h1 {
    display: none;

    &.splash-title-graphic {
      position: absolute;

      //width: 80%;
      width: 50%;
      height: 40%;
      //min-height: 40%;
      //max-height: 50%;

      @include media-breakpoint-up(sm) {
        width: 40%;
        height: 40%;
      }

      @include media-breakpoint-up(md) {
        width: 35%;
      }

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: url(../graphics/splash-logo-white.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      display: block;
      span {
        display: none;
      }
    }

    &.splash-title-graphic--hidden {
      display: none;
    }
  }

  .enter-link {
    @extend %edge-item-bottom;
    text-decoration: none;
    &:hover {
      @extend %all-caps-wide-bold;
    }
  }
}
