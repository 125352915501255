html,
body {
  height: 100%;
}

.about {
  .about-layout {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 100%;
  }

  .credits {
    box-sizing: content-box;
    margin: $cap-general-padding;
    margin-top: 0;

    .credit {
      line-height: 2em;
      margin: $cap-general-padding / 2 0;

      a {
        text-decoration: underline;
      }
    }
  }
  .support-credit {
    a {
      display: inline-block;
      text-decoration: none;
    }

    span {
      display: inline-block;
      padding-right: 0.5em;
    }

    img {
      vertical-align: middle;
      display: inline-block;
      max-height: 2em;
      max-width: 100%;
    }
  }

  @include media-breakpoint-up("sm") {
    .credits {
      font-size: 1em;
    }
  }

  @include media-breakpoint-up("md") {
    #page-container {
      height: 100%;
    }

    #page-content {
      height: calc(100% - #{$header-height});
    }

    .credits {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: $cap-general-padding $cap-general-padding * 2;

      font-size: 0.8em;
    }
  }

  @include media-breakpoint-up("lg") {
    .credits {
      font-size: 0.8em;
    }
  }
}
