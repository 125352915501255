$FontFamily: 'Gill Sans CE';
$FontPath: '../fonts/gill-sans-ce';
$FontName: 'GillSansCE';
$FontVersion: '1';

@mixin familyfontdefs(
  $FontType: 'Regular',
  $FontWeight: 400,
  $FontStyle: normal
) {
  @include fontdefs(
    $FontFamily,
    $FontPath,
    $FontName,
    $FontVersion,
    $FontType,
    $FontWeight,
    $FontStyle
  );
}

// @include familyfontdefs('Light', 300, normal);
// @include familyfontdefs('LightItalic', 300, italic);
@include familyfontdefs('Regular', 400, normal);
@include familyfontdefs('Regular', normal, normal);
@include familyfontdefs('Italic', 400, italic);
@include familyfontdefs('Italic', normal, italic);
@include familyfontdefs('SemiBold', 600, normal);
@include familyfontdefs('SemiBoldItalic', 600, italic);
@include familyfontdefs('Bold', 700, normal);
@include familyfontdefs('Bold', bold, normal);
// @include familyfontdefs('BoldItalic', 700, italic);
// @include familyfontdefs('BoldItalic', bold, italic);
// @include familyfontdefs('UltraBold', 800, normal);
