/* ==========================================================================
   # Link
   ========================================================================== */

@mixin tl-link {
  a {
    color: inherit;
    text-decoration: underline;

    &.plain {
      text-decoration: none;
    }
    &:focus,
    &:hover {
      color: inherit;
    }
  }
}
