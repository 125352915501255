.photo-fixed-layout {
  position: relative;

  .photo-fixed-layout-element {
    display: block;
    position: absolute;
    background: rgba(128, 128, 128, 0.25);

    noscript {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .photo,
    .video {
      min-height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
    }

    &.video-imagefallback {
      img {
        display: block;
      }
      video {
        display: none;
      }
    }
  }
}

.photo-horizontal-scroll-layout {
  position: relative;
  .photo {
    display: block;
    outline: none;

    img {
      display: block;
      width: 100%;
    }
    video {
      display: block;
      width: 100%;
    }

    $counts-per-breakpoint: (
      "xs": 2,
      "sm": 3,
      "md": 4,
      "lg": 5,
    );

    @each $breakpoint, $count-of-breakpoint in $counts-per-breakpoint {
      @include media-breakpoint-up($breakpoint) {
        $vw-width: 100vw / $count-of-breakpoint;
        width: $vw-width;
        &.photo-double-width {
          width: $vw-width * 2;
        }
      }
    }

    &:nth-child(even) {
      img {
        padding-bottom: 50%;
      }
      &.photo-double-width {
        img {
          padding-bottom: 25%;
        }
      }
    }
    &:nth-child(odd) {
      img {
        padding-top: 50%;
      }
      &.photo-double-width {
        img {
          padding-top: 25%;
        }
      }
    }
  }
}

.photo-grid-layout {
  position: relative;

  $photo-grid-h-padding: 0;

  .row {
    margin-left: -$photo-grid-h-padding / 2;
    margin-right: -$photo-grid-h-padding / 2;

    .photo[class^="col-"],
    .photo[class*=" col-"] {
      margin: 0;
      padding-left: $photo-grid-h-padding / 2;
      padding-right: $photo-grid-h-padding / 2;
      margin-bottom: $photo-grid-h-padding;
    }
  }

  .photo {
    img,
    video {
      display: block;
      width: 100%;
      height: auto;
      min-height: 1px;
    }
  }
}

.photo-grid-layout__link {
  display: block;
}

.photo-grid-layout {
  .photo-grid-layout__aspect-box {
    position: relative;
    .photo-grid-layout__link {
      @include absolute-fill-parent;
    }
    img,
    video {
      @include absolute-fill-parent;
      @include object-fit-cover;
    }
  }
}

.fullscreen-media {
  @include absolute-fill-parent;

  img,
  video {
    @include absolute-fill-parent;

    object-fit: cover;

    display: block;
  }
}
