@mixin fontsrc(
  $FontPath,
  $FontName,
  $FontVersion: '1.0.0',
  $FontType: 'Regular'
) {
  src: url('#{$FontPath}/#{$FontName}-#{$FontType}.woff2?v=#{$FontVersion}')
      format('woff2'),
    url('#{$FontPath}/#{$FontName}-#{$FontType}.woff?v=#{$FontVersion}')
      format('woff'),
    url('#{$FontPath}/#{$FontName}-#{$FontType}.ttf?v=#{$FontVersion}')
      format('ttf'),
    url('#{$FontPath}/#{$FontName}-#{$FontType}.svg?v=#{$FontVersion}#{$FontName}-#{$FontType}')
      format('svg'),
    url('#{$FontPath}/#{$FontName}-#{$FontType}.eot?v=#{$FontVersion}')
      format('eot');
}

@mixin fontdefs(
  $FontFamily,
  $FontPath,
  $FontName,
  $FontVersion: '1.0.0',
  $FontType: 'Regular',
  $FontWeight: 400,
  $FontStyle: normal
) {
  @font-face {
    font-family: $FontFamily;
    @include fontsrc($FontPath, $FontName, $FontVersion, $FontType);
    font-weight: $FontWeight;
    font-style: $FontStyle;
  }
}
