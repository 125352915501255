$cap-general-padding: 24px;

$cap-grid-spacing-factors: (
  'xs': 0.4,
  'sm': 0.4 + (1 - 0.4) * (1/4),
  'md': 0.4 + (1 - 0.4) * (2/4),
  'lg': 0.4 + (1 - 0.4) * (3/4),
  'xl': 1,
  'xxl': 1.1
);

$cap-grid-text-smallest: 0.7;
$cap-grid-text-factors: (
  'xs': $cap-grid-text-smallest,
  'sm': $cap-grid-text-smallest + (1 - $cap-grid-text-smallest) * (1/4),
  'md': $cap-grid-text-smallest + (1 - $cap-grid-text-smallest) * (2/4),
  'lg': $cap-grid-text-smallest + (1 - $cap-grid-text-smallest) * (3/4),
  'xl': 1,
  'xxl': 1.1
);
