/* ==========================================================================
   # List
   ========================================================================== */

@mixin tl-list {
  dl,
  ol,
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
    dl,
    ol,
    ul {
      font-size: 90%;
      margin: 1.5em 0 1.5em 3em;
    }
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: circle inside;
  }
}
