.bg-video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.bg-video-container {
  video,
  img {
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background: #000;
  }

  &.video-imagefallback {
    img {
      display: block;
    }
    video {
      display: none;
    }
  }

  &.video-align-center {
    video,
    img {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.video-align-bottom {
    video,
    img {
      top: 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
    }
  }
}
