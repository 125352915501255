@keyframes image-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.photo-fullscreen-cover {
  @include absolute-fill-parent;
  overflow: hidden;
  z-index: 0;

  .photo {
    @include absolute-fill-parent;
    img {
      @include absolute-fill-parent;

      object-fit: cover;

      display: block;
    }
  }

  &.photo-slides {
    .photo {
      display: none;
      z-index: 0;

      .page-nojs &:first-child {
        display: block;
      }
    }

    .photo.keep-visible {
      display: block;
      z-index: 0;
    }

    .photo.image-fade-in {
      z-index: 100;
      animation-name: image-fade;
      animation-direction: normal;
      animation-fill-mode: both;
    }
    .photo.current-slide {
      z-index: 1;
      display: block;
    }
  }
}

.fullscreen-media {
  @include absolute-fill-parent;

  img,
  video {
    @include absolute-fill-parent;

    object-fit: cover;

    display: block;
  }
}
