html {
  font-size: 10px;
}

body {
  font-family: 'Gill Sans CE', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1.2;
  font-size: 1.2em;
  @include media-breakpoint-up(sm) {
    font-size: 1.5em;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 1.9em;
    font-size: 1.17vw;
  }

  background-color: #fff;
  color: #000;
}
