/* ==========================================================================
   # Table
   ========================================================================== */

@mixin tl-table {
  table {
    border-spacing: 0;
    width: 100%;
  }

  td,
  th {
    border-bottom: 0.1em solid $color-quarter-tone;
    padding: 1.2em 1.5em;
    text-align: left;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  /* ## Mobile version
   ========================================================================== */

  @include media-breakpoint-down('sm') {
    table {
      border-spacing: 0;
      display: flex;
      width: 100%;

      thead {
        border-right: solid 0.1em $color-quarter-tone;

        td,
        th {
          padding-left: 0;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 1.2em;
          }
        }
      }

      tbody {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;

        tr {
          border-right: solid 0.1em $color-quarter-tone;

          &:last-child {
            border-right: none;
          }
        }
      }

      td,
      th {
        display: block;

        &:first-child {
          padding-left: 1.2rem;
        }

        &:last-child {
          padding-right: 1.2rem;
        }
      }
    }
  }
}
