/* https://stackoverflow.com/questions/8911247/hide-iphone-html5-video-play-button */
*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
