#header {
  position: relative;
  z-index: 5;

  padding-top: $header-height;

  //Color styling
  #logo {
    margin: 0;

    svg {
      display: block;
      fill: #000;
    }
  }

  //Transparent
  &.header-transparent {
    .header-primary-items {
      background: transparent !important;
    }
  }
  @include media-breakpoint-up("md") {
    &.header-transparent {
      .header-content {
        background: transparent !important;
      }
    }
  }

  //Light
  .header-primary-items {
    background: transparent;
    background: rgba(255, 255, 255, 0);

    transition: background-color 400ms;
  }
  body.scrolled & {
    .header-primary-items {
      background: #fff;
      background: rgba(255, 255, 255, 1);
    }
  }

  @include media-breakpoint-up("md") {
    .header-content {
      background: #fff;
    }
  }

  //Dark
  body.dark & {
    color: #fff;

    #logo svg {
      fill: #fff;
    }

    .header-primary-items {
      background: transparent;
      background: rgba(0, 0, 0, 0);
    }

    @include media-breakpoint-up("md") {
      .header-content {
        background: #000;
      }
    }
  }
  body.dark.scrolled & {
    .header-primary-items {
      background: #000;
      background: rgba(0, 0, 0, 1);
    }
  }

  // Desktop

  // Header
  // 	header-placeholder -> Static
  // 	header-content -> Fixed
  // 		header-primary-items -> float:left
  // 		header-secondary-items -> float:right
  // 			nav

  // Mobile

  // Header
  // 	header-placeholder -> Static
  // 	header-content -> Static
  // 		header-primary-items -> Fixed
  // 			… -> floats
  // 		header-secondary-items -> Static
  // 			nav

  .header-primary-items {
    z-index: 2;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    padding: $cap-general-padding / 2 $cap-general-padding;

    @include clearfix();
  }

  #logo {
    padding: $cap-general-padding / 2 0;
    float: left;

    svg {
      height: $logo-height;
      width: $logo-height * $logo-aspect;
    }
  }

  #header-dynamic-element {
    display: block;
    float: right;
    max-width: 65%;
  }

  .header-secondary-items {
    padding: 0 $cap-general-padding;
    position: relative;
    z-index: 1;
    opacity: 1;
    transition: opacity 300ms;
  }

  body.scrolled & {
    .header-secondary-items {
      opacity: 0;
    }
  }

  nav#header-nav {
    @include all-caps-wide();
    font-size: 11.5px;
    padding: $cap-general-padding / 2 * 2/3 0;
    padding-top: 0;

    ul {
      margin: 0;
      padding: 0;

      li {
        display: block;
        padding: $cap-general-padding / 2 * 1/3 0;
        a {
          display: inline-block;
          text-decoration: none;
          position: relative;

          .nav-item-text-space {
            display: block;
            visibility: hidden;
            @include all-caps-wide();
          }

          .nav-item-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            user-select: none;

            @include all-caps-wide();
          }
        }
        &.active a,
        a:hover {
          // position: relative;
          // left: -1px;
          // text-shadow: 0.05em 0px 0px #fff;

          .nav-item-text-space {
            visibility: hidden;
          }

          .nav-item-text {
            @include all-caps-wide-bold();
          }
        }
      }
    }
  }

  @include media-breakpoint-up("md") {
    .header-content {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;

      padding: $cap-general-padding $cap-general-padding / 2;

      @include clearfix();
    }

    .header-primary-items {
      //Reset
      position: relative;
      padding: 0;
      width: auto;

      float: left;
    }

    #logo {
      padding: 0 $cap-general-padding / 2;
      float: none;

      svg {
        height: $logo-height;
        width: $logo-height * $logo-aspect;
      }
    }

    #header-dynamic-element {
      display: none;
    }

    .header-secondary-items {
      padding: 0;
      float: right;
    }

    //reset
    body.scrolled & {
      .header-secondary-items {
        opacity: 1;
      }
    }

    nav#header-nav {
      @include clearfix();
      @include no-float-line-collapse();

      padding: 0;
      float: right;
      ul {
        li {
          padding: 0 $cap-general-padding / 2;
          float: left;

          a {
            .nav-item-text {
              //Fix so the bold text overlay gets centered; visually less noticable
              left: -5px;
              right: -5px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
