/* ==========================================================================
   # Code
   ========================================================================== */

@mixin tl-code {
  code {
    background: $color-quarter-tone;
    border-radius: 0.4em;
    font-size: 86%;
    margin: 0 0.2em;
    padding: 0.2em 0.5em;
    white-space: nowrap;
  }

  pre {
    background: $color-quarter-tone;
    overflow-y: hidden;
    & > code {
      border-radius: 0;
      display: block;
      padding: 1rem 1.5em;
      white-space: pre;
    }
  }
}
