.teaser {
  $splash-color: #fff;

  color: $splash-color;
  .page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    display: none;
  }

  .instagram-link {
    @extend %edge-item-bottom;
    text-decoration: none;
    &:hover {
      font-weight: 700;
    }
  }
}
