body.dark {
  background-color: #000;
  color: #fff;

  // Although a bit heavy for white text on black, default font smoothing is still best
  // For large type, antialiased / grayscale might be better, but for small type certainly not
  // (Tested on non-retina mbp, Firefox and Chrome)
  //-webkit-font-smoothing: antialiased;
  // -moz-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -o-font-smoothing: antialiased;
}
